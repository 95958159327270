import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { yelp, facebook, instagram, zomato } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mv="20px" column center>
          <CFView h3 futura bold color="#E89A26" mb="20px">
            SOCIAL MEDIA
          </CFView>
          <CFView row center white bold>
            <CFLink href="https://www.instagram.com/kangskitchencoq/">
              <CFImage
                w="45px"
                pr="10px"
                src={instagram}
                alt="Kang's Kitchen Instagram"
              />
            </CFLink>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mt="15px" column justifyStart>
          <CFView h3 futura bold color="#E89A26" mb="15px">
            SOCIAL MEDIA
          </CFView>
          <CFView row justifyStart alignCenter white bold>
            <CFLink href="https://www.instagram.com/kangskitchencoq/">
              <CFImage
                w="45px"
                pr="10px"
                src={instagram}
                alt="Kang's Kitchen Instagram"
                hover
              />
            </CFLink>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
